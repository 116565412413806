.contact {
  padding-bottom: 60vh;
}

.contact-greet {
  font-family: Playfair Display;
  font-size: 30rem;
  letter-spacing: 2rem;
  font-style: italic;
  padding: 100rem 0 50rem 0;
  text-align: center;
  font-weight: 600;
}

.contact-links {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: fit-content;
  justify-content: space-evenly;
}
