.button-container {
  background-color: #ad986f;
  border-radius: 5rem;
  transition: background-color 0.2s ease-out;
}

.button-container:hover {
  background-color: #ffffff;
  cursor: pointer;
  border: 1px solid #ad986f;
}

.button-text {
  color: #ffffff;
  font-size: 15rem;
  font-weight: 600;
  font-style: italic;
  transition: all 0.2s ease-out;
  user-select: none;
  letter-spacing: 0.5rem;
  font-family: Playfair Display;
}

.button-container:hover .button-text {
  color: #ad986f;
  cursor: pointer;
  letter-spacing: 1.3rem;
}

@keyframes appear {
  from {
    opacity: 0;
    padding: 10rem 10rem;
  }
  to {
    opacity: 1;
    padding: 10rem 45rem;
  }
}
