html {
  background-color: #fcfcfc;
  font-family: IBM Plex Sans;
  color: #000000d6;
  font-size: 0.5px;
  overflow-x: hidden;
}
@media only screen and (min-width: 480px) {
  html {
    overflow-x: hidden;
    overflow-y: visible;
    font-size: 0.75px;
  }
}
@media only screen and (min-width: 1024px) {
  html {
    overflow-y: visible;
    font-size: 1px;
  }
}

#root {
  flex-direction: column;
  width: min(80%, 1200px);
  margin: 0 auto;
}

#loading-screen {
  background-color: #fcfcfc;
}

#loading-screen .bar-container {
  background-color: #a8a5a3;
}

#loading-screen .bar {
  background: linear-gradient(120deg, #a76407 0%, #ad986f 50%, #ad986f 100%);
}

/* START */
.inactive {
  display: none !important;
}

.sub-section {
  margin-bottom: 100rem;
}

.sub-section-title {
  font-size: 20rem;
  color: #ad986f;
  font-family: Playfair Display;
  text-align: center;
  margin: 30rem auto;
  letter-spacing: 1rem;
}

/* Animations */
@keyframes changeColor {
  from {
    color: #00000099;
  }
  to {
    color: #000000d6;
  }
}
