.proj-view-icon {
  width: 70rem;
  height: 30rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1rem solid;
  border-color: #ad986f;
  background-color: #ffffff;
  border-radius: 5rem;
  overflow: hidden;
  transition: all 0.2s ease-out;
  margin: 30rem auto;
}

.proj-view-icon:hover {
  border-color: #0a0a0a;
  cursor: pointer;
}

.proj-view-icon > div {
  position: absolute;
  height: 70%;
  width: 25%;
}

.proj-view-icon > div:nth-child(1) {
  background-color: lavender;
}
.proj-view-icon > div:nth-child(2) {
  background-color: mediumturquoise;
}
.proj-view-icon > div:nth-child(3) {
  background-color: wheat;
}

.proj-view-icon-sub-text {
  font-size: 15rem;
  line-height: 20rem;
  color: #00000099;
  font-family: Playfair Display;
  font-style: italic;
  font-weight: 600;
  text-align: center;
}
