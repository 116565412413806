.expertise {
  display: grid;
  grid-template-areas:
    "vertical-bar"
    "frontend"
    "backend"
    "cs";
  row-gap: 50rem;
}
@media only screen and (min-width: 480px) {
  .expertise {
    row-gap: 100rem;
  }
}

.frontend {
  grid-area: frontend;
}

.backend {
  grid-area: backend;
}

.cs {
  grid-area: cs;
}

.expertise-section {
  display: grid;
  flex-direction: column;
  grid-template-areas:
    "exp-header exp-header"
    "exp-text exp-text"
    "exp-skills exp-lottie";
  row-gap: 10rem;
  align-items: center;
}
@media only screen and (min-width: 480px) {
  .expertise-section:nth-child(odd) {
    column-gap: 20rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "exp-header exp-lottie"
      "exp-text exp-lottie"
      "exp-skills exp-lottie";
  }
  .expertise-section:nth-child(even) {
    column-gap: 20rem;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "exp-lottie exp-header"
      "exp-lottie exp-text"
      "exp-lottie exp-skills";
  }
}

.expertise-section h3 {
  font-family: Playfair Display;
  font-size: 30rem;
  font-weight: 600;
  grid-area: exp-header;
  letter-spacing: 0.7rem;
}

.expertise-text {
  font-size: 20rem;
  line-height: 30rem;
  color: #00000099;
  grid-area: exp-text;
}
@media only screen and (min-width: 480px) {
  .expertise-text {
    font-size: 15rem;
    line-height: 25rem;
  }
}

.expertise-skills {
  font-size: 20rem;
  line-height: 30rem;
  color: #00000099;
  grid-area: exp-skills;
  font-family: Playfair Display;
  font-weight: 600;
}
@media only screen and (min-width: 480px) {
  .expertise-skills {
    font-size: 15rem;
    line-height: 25rem;
  }
}

.expertise-skills span {
  color: #946d24;
  font-family: Playfair Display;
  font-weight: 600;
}

.expertise-lottie {
  grid-area: exp-lottie;
  justify-self: center;
}
