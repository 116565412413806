.work-section {
  display: grid;
  grid-template-areas:
    "w-info w-year"
    "w-info w-tags"
    "w-desc w-desc"
    "w-link .";
  align-items: flex-start;
}
@media only screen and (min-width: 480px) {
  .work-section {
    grid-template-areas:
      ". w-year"
      ". w-tags"
      "w-info w-desc"
      "w-link w-desc"
      ". w-desc";
    grid-template-columns: 1fr 3fr;
    grid-template-rows: fit-content;
    column-gap: 20rem;
  }
}

.work-info {
  grid-area: w-info;
  align-self: flex-start;
  justify-self: flex-start;
}

.work-section h3 {
  font-family: Playfair Display;
  font-size: 30rem;
  font-weight: 600;
  letter-spacing: 0.9rem;
}

.work-year {
  font-family: Playfair Display;
  font-size: 15rem;
  justify-self: flex-end;
  align-self: flex-start;
  grid-area: w-year;
}

.work-role {
  color: #00000099;
  font-family: Playfair Display;
  font-size: 20rem;
  line-height: 30rem;
}

.work-tags {
  font-size: 15rem;
  line-height: 20rem;
  color: #00000099;
  font-family: Playfair Display;
  font-weight: 600;
  grid-area: w-tags;
  justify-self: flex-end;
  align-self: flex-start;
}

.work-desc {
  font-size: 20rem;
  line-height: 30rem;
  color: #00000099;
  grid-area: w-desc;
  justify-self: flex-end;
  align-self: flex-start;
}
@media only screen and (min-width: 480px) {
  .work-desc {
    font-size: 15rem;
    line-height: 25rem;
    margin-top: -20rem;
  }
}

.work-link {
  grid-area: w-link;
}

.project-section {
  width: 100%;
  height: 850rem;
  position: relative;
  display: flex;
  justify-content: center;
}
@media only screen and (min-width: 480px) {
  .project-section {
    height: 550rem;
  }
}

.project-item {
  border-radius: 1%;
  background-color: #f5f5f5;
  overflow: hidden;
  box-shadow: 2rem 2rem 50rem 0rem #00000014;
  border: 5rem solid #ffffff;
  position: absolute;
  top: 0;
  height: 100%;
  cursor: grab;
  width: 85%;
}
@media only screen and (min-width: 480px) {
  .project-item {
    width: max(350rem, 20vw);
  }
}

.project-item:active {
  cursor: grabbing;
}

.project-item h3 {
  font-size: 40rem;
  font-family: Playfair Display;
  font-weight: 600;
  margin: 5rem 10rem;
  user-select: none;
}
@media only screen and (min-width: 480px) {
  .project-item h3 {
    font-size: 20rem;
  }
}

.project-year {
  color: #00000099;
  font-family: Playfair Display;
  font-size: 25rem;
  margin: 0 10rem;
  user-select: none;
}
@media only screen and (min-width: 480px) {
  .project-year {
    font-size: 15rem;
  }
}

.project-desc {
  font-size: 20rem;
  line-height: 30rem;
  color: #00000099;
  margin: 10rem;
  user-select: none;
}
@media only screen and (min-width: 480px) {
  .project-desc {
    font-size: 15rem;
    line-height: 25rem;
  }
}
