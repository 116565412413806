.img-container {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-position: top center;
  background-size: 100%;
  transition: background-size 0.2s ease-out;
}

.img-container:hover .img {
  background-size: 105%;
  filter: blur(8px);
}

.img-layer {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transition: background-color 0.2s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-container:hover .img-layer {
  background-color: #c5c5c588;
}
