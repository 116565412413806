.link-container {
  margin-top: 45rem;
  overflow: visible;
  width: fit-content;
  transition: opacity 0.2s ease-out;
}

.link-container:hover {
  cursor: pointer;
}

.link-text {
  color: #ad986f;
  font-size: 15rem;
  margin-bottom: 3rem;
  font-weight: 600;
  font-style: italic;
  letter-spacing: 0.5rem;
  font-family: Playfair Display;
}

.link-text:hover {
  color: #0a0a0a;
}

.link-text-dark:hover {
  color: #ffffff;
}

.underline {
  height: 1px;
  overflow: visible;
  margin-top: 5rem;
}
